import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';

import { BASE_URL } from '../constants';

import { authApi } from './api';

export * from './types';

export const authClient = new Zodios(BASE_URL, authApi, { axiosInstance });

export const authHooks = new ZodiosHooks('auth', authClient);
